const Configure = {
  AESKey: 'fL61wy76iB49HzbHhvcOkASBVlhXNjzS',
  es: 'https://es.caak.mn/',
  esa: { username: 'caak', password: 'ph58b4d26fb4a' },
  host: 'https://graph.caak.mn',
  // host: 'http://localhost:3005',
  cid: '1cT1tgfWggH3ZsaihJYGbYeB2rXwobijRAgui8qqtU4',
  cs: 'Z31TDT2SopqUVcXyxrymJpIxfPrsuWyuneVQIhOaz9o',
  userTokenField: 'us.t',
  appFacebookId: '832214398199458',
  appGoogleKey: '367475317387-9jh4k84vo1hkoehf520lmsrdanfvaqsk.apps.googleusercontent.com',
  domain: 'https://www.caak.mn',
};

export default Configure;
